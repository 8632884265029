import {
  Collapse,
  createTheme,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { ReactComponent as BurgerIcon } from "../assets/svgs/burger.svg";
import { BASE_URL, GetRouteGroupsResponse, RouteInfoV2 } from "../utils/api";
import { COLORS } from "../utils/colors";
import AppButton from "./appButton";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    marginTop: 50,
    marginBottom: 50,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  start: {
    color: COLORS.green,
  },
  menuButton: {
    marginLeft: 16,
    backgroundColor: COLORS.white,
  },
  menuButtonBuy: {
    marginLeft: 16,
  },
  menuItem: {
    marginTop: 4,
    marginBottom: 4,
  },
  buttonContainer: {
    marginLeft: 16,
    marginRight: 16,
  },
  buyButton: {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    marginLeft: 16,
  },
}));

export const orangeTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.orange,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontWeight: 600,
        fontSize: 14,
        textTransform: "none",
        fontFamily: [
          "Inter",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
    MuiFab: {
      label: {
        fontWeight: 600,
        fontSize: 14,
        textTransform: "none",
        fontFamily: [
          "Inter",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
  },
});

const AppHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);

  const toggleDrawer = (event: any) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const { data } = useQuery<GetRouteGroupsResponse>(
    ["GetRouteGroup"],
    async () => {
      try {
        const res = await fetch(`${BASE_URL}GetRouteGroups`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          },
        });
        return res.json();
      } catch (e) {
        console.log(e);
      }
    }
  );

  const routeGroups = data?.Data.RouteGroupsList;

  return (
    <>
      {isMobile && (
        <>
          <IconButton aria-label="menu" onClick={toggleDrawer}>
            <BurgerIcon />
          </IconButton>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
            <div className={classes.list} role="presentation">
              {routeGroups?.map((routeGroup, index) => (
                <MobileMenuItem
                  title={routeGroup.GroupName}
                  routes={routeGroup.Routes}
                  open={index === 0}
                  key={`${routeGroup.GroupName}_${index}`}
                />
              ))}
            </div>
            <div className={classes.buttonContainer}>
              <ThemeProvider theme={orangeTheme}>
                <AppButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  // @ts-ignore
                  target="_blank"
                  href="https://ticketbooking.jjkavanagh.ie/BookTicket/TicketSelection"
                >
                  Buy Tickets
                </AppButton>
              </ThemeProvider>
            </div>
          </SwipeableDrawer>
        </>
      )}
      {isNotMobile && (
        <div>
          {routeGroups?.map((routeGroup, index) => (
            <DesktopMenuItem
              title={routeGroup.GroupName}
              routes={routeGroup.Routes}
              key={`${routeGroup.GroupName}_${index}`}
            />
          ))}
          <ThemeProvider theme={orangeTheme}>
            <AppButton
              color="primary"
              variant="contained"
              // @ts-ignore
              target="_blank"
              href="https://ticketbooking.jjkavanagh.ie/BookTicket/TicketSelection"
              className={classes.menuButtonBuy}
            >
              Buy Tickets
            </AppButton>
          </ThemeProvider>
        </div>
      )}
    </>
  );
};

interface MobileMenuItemProps {
  routes: RouteInfoV2[];
  title: string;
  open?: boolean;
}

const MobileMenuItem = ({ routes, title, open }: MobileMenuItemProps) => {
  const classes = useStyles();

  const [openMainRoutes, setOpenMainRoutes] = React.useState(!!open);
  const handleMainRoutesClick = () => {
    setOpenMainRoutes(!openMainRoutes);
  };

  const renderListItem = (route: RouteInfoV2, index: number) => (
    <ListItem
      key={`${route.RouteID}_${index}`}
      button
      className={classes.nested}
      component="a"
      href={`/dashboard/${route.RouteNo}?direction=0&routeId=${route.RouteID}`}
    >
      <ListItemText>
        <span className={classes.start}>
          {route.OutwardName.split(" to ")[0]}
        </span>{" "}
        to {route.OutwardName.split(" to ").slice(1).join("")}
      </ListItemText>
    </ListItem>
  );

  return (
    <>
      <ListItem button onClick={handleMainRoutesClick}>
        <ListItemText primary={title} />
        {openMainRoutes ? (
          <ExpandLess color="primary" />
        ) : (
          <ExpandMore color="primary" />
        )}
      </ListItem>

      <Collapse in={openMainRoutes} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {routes.map(renderListItem)}
        </List>
      </Collapse>
    </>
  );
};

interface DesktopMenuItemProps {
  routes: RouteInfoV2[];
  title: string;
}

const DesktopMenuItem = ({ routes, title }: DesktopMenuItemProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMainRoutesButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = (route: RouteInfoV2, index: number) => (
    <MenuItem
      key={`${route.RouteID}_${index}`}
      onClick={handleClose}
      component="a"
      href={`/dashboard/${route.RouteNo}?direction=0&routeId=${route.RouteID}`}
      className={classes.menuItem}
    >
      <div>
        <span className={classes.start}>
          {route.OutwardName.split(" to ")[0]}
        </span>
        {" to "}
        {route.OutwardName.split(" to ").slice(1).join("")}
      </div>
    </MenuItem>
  );

  return (
    <>
      <AppButton
        aria-controls="main-routes-menu"
        aria-haspopup="true"
        onClick={handleMainRoutesButtonClick}
        className={classes.menuButton}
      >
        {title}
      </AppButton>
      <Menu
        id="main-routes-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {routes.map(renderMenuItem)}
      </Menu>
    </>
  );
};

export default AppHeader;
