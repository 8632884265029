import {
  AppBar,
  Container,
  IconButton,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import AppHeader from "./components/appHeader";
import AppLogo from "./components/appLogo";
import Dashboard from "./screens/Dashboard";
import Home from "./screens/Home";
import { theme } from "./utils/theme";

import { ReactComponent as BackButton } from "./assets/svgs/back-button.svg";

import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";

// Create a client
const queryClient = new QueryClient();

ReactGA.initialize("UA-21345175-1", {
  titleCase: false,
});

const useStyles = makeStyles({
  container: {
    background: "#FCFCFC",
  },
  header: {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: {
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
  },
});

function usePageViews() {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

function App() {
  const classes = useStyles();

  usePageViews();

  return (
    <HelmetProvider>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <div className={classes.container}>
              <AppBar position="static" color="transparent" elevation={0}>
                <Container className={classes.header}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Link to="/">
                          <AppLogo />
                        </Link>
                      }
                    />
                    <Route
                      path="/dashboard/:routeID"
                      element={
                        <Link to="/">
                          <IconButton
                            className={classes.backButton}
                            aria-label="back to homepage"
                          >
                            <BackButton />
                          </IconButton>
                        </Link>
                      }
                    />
                  </Routes>
                  <AppHeader />
                </Container>
              </AppBar>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/:routeID" element={<Dashboard />} />
              </Routes>
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}

export default App;
