import {
  Container,
  Grid,
  makeStyles,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useQuery } from "react-query";
import { ReactComponent as ClockIcon } from "../assets/svgs/clock.svg";
import LargeCardV2 from "../components/largeCardV2";
import { BASE_URL, GetRouteGroupsResponse, RouteInfoV2 } from "../utils/api";
import { COLORS } from "../utils/colors";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 60,
  },
};

const useStyles = makeStyles({
  title: {
    marginBottom: 8,
    marginTop: 32,
  },
  subTitle: {
    marginBottom: 48,
    fontWeight: 500,
  },
  sectionTitle: {
    marginBottom: 10,
    fontSize: 24,
  },
  container: {
    paddingBottom: 60,
  },
  carousel: {
    paddingBottom: 24,
    paddingLeft: 16,
    marginLeft: -16,
    marginRight: -16,
  },
  carouselItem: {
    paddingRight: 24,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: COLORS.green,
    color: COLORS.white,
    border: `2px solid ${COLORS.green}`,
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: 24,
    "&:focus-visible": {
      outline: "none",
    },
  },
  paperIcon: {
    marginTop: 24,
    marginBottom: 24,
  },
  paperLink: {
    color: COLORS.white,
    textDecoration: "none",
  },
});

export default function Home() {
  const classes = useStyles();
  const title = "Real-Time Information is Now Live | JJ Kavanagh & Sons";
  const [open, setOpen] = React.useState(false);
  const [cookies, setCookie] = useCookies(["firstTimeUser"]);

  useEffect(() => {
    if (cookies.firstTimeUser === "true") {
      handleOpen();
      setCookie("firstTimeUser", false, { path: "/" });
    } else if (cookies.firstTimeUser === undefined) {
      setCookie("firstTimeUser", true, { path: "/" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data } = useQuery<GetRouteGroupsResponse>(
    ["GetRouteGroup"],
    async () => {
      try {
        const res = await fetch(`${BASE_URL}GetRouteGroups`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          },
        });
        return res.json();
      } catch (e) {
        console.log(e);
      }
    }
  );

  const routeGroups = data?.Data.RouteGroupsList;

  return (
    <>
      <Container>
        <Helmet>
          <title>{title}</title>
          <meta name="og:title" content={title} />
        </Helmet>
        <Typography className={classes.title} variant="h1">
          All Routes
        </Typography>
        <Typography className={classes.subTitle} variant="h3">
          For real time bus information please select your route below
        </Typography>
        {routeGroups?.length &&
          routeGroups.map((routeGroup) => (
            <HomeRouteRow
              key={routeGroup.GroupName}
              route={routeGroup.Routes}
              title={routeGroup.GroupName}
            />
          ))}
      </Container>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h1">Real-Time Information Survey</Typography>
            <div className={classes.paperIcon}>
              <ClockIcon width={100} height={100} />
            </div>
            <Typography
              variant="h3"
              className={classes.paperLink}
              component="a"
              target="_blank"
              href="https://www.surveymonkey.com/r/JJKRealTimeInfo"
            >
              <i>Click here</i> to complete survey
            </Typography>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

interface HomeRouteRowProp {
  route: RouteInfoV2[];
  title: string;
}

const HomeRouteRow = (props: HomeRouteRowProp) => {
  const { route, title } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Typography className={classes.sectionTitle} variant="h2">
        {title}
      </Typography>
      <Typography variant="h4">
        {title.includes("College") && (
          <span>Monday - Friday (College Term Only)</span>
        )}
      </Typography>
      {matches ? (
        <Carousel
          responsive={responsive}
          arrows={false}
          partialVisible
          containerClass={classes.carousel}
        >
          {route?.length &&
            route.map((route) => (
              <div className={classes.carouselItem} key={route.RouteID}>
                <LargeCardV2 {...route} />
              </div>
            ))}
        </Carousel>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              justify="flex-start"
              spacing={2}
              className={classes.container}
            >
              {route?.length &&
                route.map((route) => (
                  <Grid item key={route.RouteID} xs={matches && 12}>
                    <LargeCardV2 {...route} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
